import * as React from "react"
import styled from "styled-components"
import { Content } from "./content"
import { EyecatchImage } from "./eyecatchImage"
import { PostHead } from "./postHead"
import { PostNavigation } from "./postNavigation"
import { Sidebar } from "./sidebar"
import { Alignwide, MyContainer, MyGrid } from "./styles"

export const StyledWrapper = styled.main`
  ${MyContainer}

  > div {
    ${Alignwide}
    ${MyGrid}
		row-gap: 30px;
    padding: 30px 0;
  }
`

export const StyledPage = styled.article`
  @media (min-width: 768px) {
    grid-column: 1 / span 8 !important;
  }
`

export const StyledArticle = styled.div`
  display: grid;
  row-gap: 30px;
  background-color: var(--white);
  padding: 30px 0;
`

export const Wrapper = ({
  date,
  dateJP,
  modified,
  modifiedJP,
  title,
  categories,
  image,
  alt,
  featuredImage,
  content,
  next,
  previous,
}) => {
  return (
    <StyledWrapper>
      <div>
        <StyledPage>
          <StyledArticle>
            <PostHead
              date={date}
              dateJP={dateJP}
              modified={modified}
              modifiedJP={modifiedJP}
              title={title}
              categories={categories}
            />

            <EyecatchImage
              featuredImage={featuredImage}
              image={image}
              alt={alt}
            />

            <Content content={content} />

            {categories && <PostNavigation next={next} previous={previous} />}
          </StyledArticle>
        </StyledPage>
        <Sidebar />
      </div>
    </StyledWrapper>
  )
}
