import * as React from "react"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const StyledPostNavigation = styled.nav`
  padding: 0 10px;
  margin-top: 30px;

  .nav-links {
    display: grid;
    row-gap: 10px;
  }

  a {
    display: grid;
    justify-items: center;
    align-items: center;
    border: solid 1px currentColor;
    padding: 10px;
  }

  .nav-previous {
    a {
      grid-template-columns: 1fr 9fr;

      span {
        justify-self: start;
      }
    }
  }

  .nav-next {
    a {
      grid-template-columns: 9fr 1fr;

      span {
        justify-self: end;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0 30px;
  }
`

export const PostNavigation = ({ next, previous }) => {
  return (
    <StyledPostNavigation
      className="post-navigation"
      role="navigation"
      aria-label="投稿"
    >
      <h2 className="screen-reader-text">投稿ナビゲーション</h2>
      <div className="nav-links">
        {next && (
          <div className="nav-previous">
            <Link to={`/blog/${next.slug}/`} rel="prev">
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{next.title}</span>
            </Link>
          </div>
        )}
        {previous && (
          <div className="nav-next">
            <Link to={`/blog/${previous.slug}/`} rel="next">
              <span>{previous.title}</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </div>
        )}
      </div>
    </StyledPostNavigation>
  )
}
