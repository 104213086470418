import * as React from "react"
import styled from "styled-components"

export const StyledContent = styled.div`
  display: grid;
  row-gap: 30px;
  padding: 0 10px;

  h2 {
    background-color: var(--background);
    margin: 0;
    font-size: var(--f3);
    font-weight: normal;
    padding: 15px;
    border-left: solid 14px var(--blue);
  }

  h3 {
    margin: 0;
    font-weight: normal;
    padding: 5px 15px;
    border-left: solid 10px var(--blue);
  }

  p {
    margin: 0;

    a {
      color: var(--blue);
    }
  }

  ul {
    display: grid;
    row-gap: 10px;
    margin: 0;
    padding: 30px;
    padding-left: 45px;
    border: dashed 1px var(--blue);
    background-color: var(--blueBack);
  }

  @media (min-width: 768px) {
    padding: 0 30px;

    h2 {
      font-size: var(--f2);
    }
  }
`

export const Content = ({ content }) => {
  return <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
}
