import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Sidebar } from "../components/sidebar"
import { StyledWrapper } from "../components/wrapper"

const StyledNotFount = styled.div`
  display: grid;
  row-gap: 30px;
  align-content: start;

  h1 {
    font-weight: normal;
  }

  a {
    font-size: var(--f4);
    color: var(--blue);
  }

  @media (min-width: 768px) {
    grid-column: 1 / span 8 !important;
  }
`

const NotFoundPage = ({ location }) => (
  <Layout>
    <Seo pagetitle="ページが見つかりません" pagepath={location.pathname} />
    <StyledWrapper>
      <div>
        <StyledNotFount>
          <h1>お探しのページは見つかりませんでした</h1>
          <Link to={`/blog/`}>＞＞トップページへ</Link>
        </StyledNotFount>
        <Sidebar />
      </div>
    </StyledWrapper>
  </Layout>
)

export default NotFoundPage
